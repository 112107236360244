import { flowRight, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import AccessibilityListener from '../../../app/components/accessibility-listener';
import AppWrapper from '../../../app/components/app-wrapper';
import AppLoaded from '../../../app/components/app-loaded';
import { NoPosts, Unauthenticated } from '../empty-state';
import RecentPosts from '../recent-posts';
import withLayoutProps from '../../hoc/with-layout-props';
import withPermissions from '../../../app/hoc/with-permissions';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { getCategory } from '../../../common/selectors/categories-selectors';
import {
  getWidgetCategoryId,
  getWidgetPostsOrderBy,
} from '../../selectors/component-data-selectors';
import { getRecentPosts } from '../../selectors/recent-posts-selectors';
import styles from './widget.scss';
import { isDebug, isProduction } from '../../../common/store/basic-params/basic-params-selectors';

class Widget extends Component {
  componentDidUpdate = ({ categoryId, numberOfPosts, postOrderBy }) => {
    const numberOfPostsChanged = numberOfPosts !== this.props.numberOfPosts;
    const categoryChanged = categoryId !== this.props.categoryId;
    const postOrderByChanged = postOrderBy !== this.props.postOrderBy;
    const shouldReset = categoryChanged || numberOfPostsChanged || postOrderByChanged;

    if (shouldReset) {
      this.fetchPosts();
    }
  };

  fetchPosts = () => {
    const { fetchRecentPosts, categoryId, numberOfPosts, postOrderBy } = this.props;
    fetchRecentPosts({ categoryId, pageSize: numberOfPosts, orderBy: postOrderBy });
  };

  renderPosts() {
    const { can, posts, isPostNumbersEnabled, category } = this.props;
    if (category && !can('read', 'category', category)) {
      return <Unauthenticated />;
    }

    if (isEmpty(posts)) {
      return <NoPosts category={category} />;
    }

    return <RecentPosts posts={posts} isPostNumbersEnabled={isPostNumbersEnabled} />;
  }

  render() {
    const { t, isDebug } = this.props;

    if (isDebug) {
      console.log('WidgetRoot', this.props);
    }

    return (
      <AppWrapper
        className={classNames(styles.widget, 'forum-background-color')}
        aria-label={t('recent-posts-widget.recent-posts')}
        tabIndex="0"
      >
        <AppLoaded />
        <AccessibilityListener />
        {this.renderPosts()}
      </AppWrapper>
    );
  }
}

Widget.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object),
  isPostNumbersEnabled: PropTypes.bool,
  isDebug: PropTypes.bool,
  fetchRecentPosts: PropTypes.func,
  numberOfPosts: PropTypes.number,
  categoryId: PropTypes.string,
  layoutName: PropTypes.string,
  layoutConfig: PropTypes.object,
  postOrderBy: PropTypes.string,
  category: PropTypes.object,
  can: PropTypes.func,
  dimensions: PropTypes.object,
  interactionStarted: PropTypes.func,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const categoryId = getWidgetCategoryId(state);
  const postOrderBy = getWidgetPostsOrderBy(state);

  return {
    categoryId,
    posts: getRecentPosts(state),
    postOrderBy,
    dimensions: host.dimensions,
    category: getCategory(state, categoryId),
    fetchRecentPosts: actions.fetchRecentPosts,
    interactionStarted: actions.interactionStarted,
    isDebug: isDebug(state) || !isProduction(state),
  };
};

export default flowRight(
  connect(mapRuntimeToProps),
  withLayoutProps,
  withPermissions,
  withTranslate,
)(Widget);
