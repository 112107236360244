import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import PostLink from '../post-link';
import PostHeader from '../../../app/components/post-header';
import PostTitle from '../../../app/components/post-title';
import PostItemCover from '../post-item-cover';
import styles from './recent-posts-item.scss';
import { flowRight } from 'lodash';
import { connect } from '../../../common/components/runtime-context';
import { getCategory } from '../../../common/selectors/categories-selectors';
import { getReactionsDisplayState } from '../../../app/containers/post-reactions/post-reactions-selectors';
import withPermissions from '../../../app/hoc/with-permissions';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { PostItemFooterNew } from '../post-item-footer/post-item-footer-new';

const RecentPostsItem = ({
  post,
  titleFontClassName,
  titleFontColorClassName,
  withCover,
  isPostNumbersEnabled,
  forPublicUser,
  isMobile,
  category,
  onReactionClick,
  reactions,
  t,
}) => {
  const renderContent = (
    <div className={styles.contentContainer}>
      <div
        className={classNames(titleFontClassName, titleFontColorClassName)}
        data-hook="recent-post__title"
      >
        <PostLink post={post} className={classNames(styles.link, 'forum-link-hover-color')}>
          <PostTitle
            className={classNames(styles.title)}
            title={post.title}
            post={post}
            type={PostTitle.RECENT_POSTS_WIDGET}
            clamp={isMobile ? 1 : 2}
          />
        </PostLink>
      </div>
      {withCover && (
        <div className={styles.cover}>
          <PostItemCover post={post} height={48} width={48} />
        </div>
      )}
    </div>
  );

  const renderHeader = (
    <div
      className={classNames(
        styles.header,
        titleFontClassName,
        titleFontColorClassName,
        'icon-fill',
      )}
    >
      <PostHeader
        type={PostHeader.RECENT_POSTS_WIDGET}
        post={post}
        showPinIcon={false}
        showLockIcon={false}
        showDateBelowAvatar={true}
        showMoreButton={false}
        showMemberCard={false}
        showUserInfoInline={false}
        truncateBadgeTitle={true}
      />
    </div>
  );

  const renderFooter = (
    <PostItemFooterNew
      reactions={reactions}
      forPublicUser={forPublicUser}
      onReactionClick={onReactionClick}
      post={post}
      category={category}
      isMobile={isMobile}
      titleFontClassName={titleFontClassName}
      titleFontColorClassName={titleFontColorClassName}
      t={t}
    />
  );

  return (
    <div className={classNames(styles.main)}>
      <div className={classNames(styles.content)}>
        {renderHeader}
        {renderContent}
      </div>
      <div>{isPostNumbersEnabled && renderFooter}</div>
    </div>
  );
};

RecentPostsItem.propTypes = {
  post: PropTypes.object.isRequired,
  titleFontClassName: PropTypes.string.isRequired,
  titleFontColorClassName: PropTypes.string.isRequired,
  navigation: PropTypes.object,
  withCover: PropTypes.bool.isRequired,
  isPostNumbersEnabled: PropTypes.bool.isRequired,
  forPublicUser: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  category: PropTypes.object,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => ({
  category: getCategory(state, ownProps.post.categoryId),
  reactions: getReactionsDisplayState(state, ownProps.post._id),
  onReactionClick: (reactionCode) => {
    return actions.reactToPost({
      postId: ownProps.post._id,
      reactionCode,
    });
  },
});

export default flowRight(
  withPermissions,
  withTranslate,
  connect(mapRuntimeToProps),
)(RecentPostsItem);
